/* RESPONSIBLE TEAM: team-self-serve */
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class AppsAppWizard extends Controller {
  queryParams = ['wizardId', 'stepId', 'source'];
  @tracked wizardId = null;
  @tracked stepId = null;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'apps/app/wizard': AppsAppWizard;
  }
}
