/* RESPONSIBLE TEAM: team-product-exploration */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import {
  type NavSection,
  SETTINGS_NAV_ITEMS,
} from 'embercom/components/new-settings/navigation/submenu-items';

export default class IndexController extends Controller {
  queryParams = [];
  @service declare appService: $TSFixMe;
  @service declare customerService: $TSFixMe;

  get settingsStructure() {
    return SETTINGS_NAV_ITEMS.filter((item) => !item.hasNoChildren);
  }

  @action
  shouldShowSubSection(section: NavSection) {
    if (this.appService.app.canUseStandalone && !section.showForStandaloneApps) {
      return false;
    }

    if (section.showOnlyOnFeatureFlag) {
      return this.appService.app.canUseFeature(section.showOnlyOnFeatureFlag);
    }
    if (section.customDisplayCondition) {
      return section.customDisplayCondition(this.appService.app, this.customerService.customer);
    }

    return true;
  }
}
