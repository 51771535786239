/* RESPONSIBLE TEAM: team-ai-agent */
import Controller, { inject as controller } from '@ember/controller';
import ajax from 'embercom/lib/ajax';
import CustomAnswer from 'embercom/models/custom-answers/custom-answer';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

const MAX_CLUSTER_SUGGESTIONS = 30;

export default class EditController extends Controller {
  @controller('apps/app/automation/fin-ai-agent/custom-answers/answer') answerController;

  @service appService;
  @service router;

  queryParams = ['suggestionId'];

  suggestionId = null;
  isLoadingSuggestedAnswerClusters = false;

  get modelToReload() {
    return {
      reload: () => CustomAnswer.loadFullAnswer(this.model.id),
    };
  }

  get afterCancelRouteName() {
    if (this.answerController.transitionBackTo) {
      return this.answerController.transitionBackTo;
    } else if (this.redirectToShowPageOnCancel) {
      return `${this.appService.app.answersRoute}.answer.show.index`;
    } else {
      return this.appService.app.answersRoute;
    }
  }

  get redirectToShowPageOnCancel() {
    if (this.previousShowPageAnswerId && this.previousShowPageAnswerId !== this.model.id) {
      return false;
    }
    return this.transitionedFromAnswerShowPage;
  }

  @action
  async updateSuggestedAnswerClusters() {
    let suggestedAnswerClusters = [];
    let answer = this.model;

    try {
      if (!answer.hasExampleQuestionsWithContent) {
        this.set('isLoadingSuggestedAnswerClusters', true);
        suggestedAnswerClusters = await this.findClusters({ language: answer.languageCode });
      }
    } finally {
      this.set('suggestedAnswerClusters', suggestedAnswerClusters);
      this.set('isLoadingSuggestedAnswerClusters', false);
    }
  }

  async findClusters({ language = 'en', interval = 90 }) {
    try {
      let { cluster_ids: clusterIds, cluster_run_id: clusterRunId } =
        await this._fetchSuggestedAnswerClusterIds({ language, interval });

      if (clusterIds.length) {
        let rawClusters = await this._fetchSuggestedAnswerClusters({
          interval,
          clusterRunId,
          clusterIds: clusterIds.slice(0, MAX_CLUSTER_SUGGESTIONS),
        });

        if (rawClusters.length) {
          return rawClusters.map((c) => this._buildCluster(c));
        }
      }

      return [];
    } catch (_e) {
      return [];
    }
  }

  _fetchSuggestedAnswerClusterIds({ language, interval }) {
    return ajax({
      url: '/ember/custom_answers/suggested_answer_cluster_ids',
      type: 'GET',
      data: {
        interval,
        app_id: this.appService.app.id,
        admin_id: this.appService.app.currentAdmin.id,
        ...(language && { locale: language }),
      },
    });
  }

  _fetchSuggestedAnswerClusters({ clusterIds, clusterRunId, interval }) {
    return ajax({
      url: '/ember/custom_answers/suggested_answer_clusters',
      type: 'GET',
      data: {
        interval,
        cluster_ids: clusterIds,
        cluster_run_id: clusterRunId,
        app_id: this.appService.app.id,
        admin_id: this.appService.app.currentAdmin.id,
      },
    });
  }

  _buildCluster(cluster) {
    return {
      // mock custom-answers/cluster model
      id: cluster.cluster_id,
      archetype: { id: cluster.cluster_id, text: cluster.archetype_text },
      examples: [{ id: cluster.cluster_id, text: cluster.archetype_text }],
      languageAlpha2: cluster.locale,
    };
  }
}
