/* RESPONSIBLE TEAM: team-ai-agent */
/* eslint-disable ember/no-controllers */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type Template from 'embercom/models/template';
import type Store from '@ember-data/store';
import { post } from 'embercom/lib/ajax';
import { captureException } from 'embercom/lib/sentry';
import { FIN_LANDING_PAGE_PAGEVIEW_KEY } from 'embercom/lib/operator/resolution-bot/constants';
import type IntlService from 'ember-intl/services/intl';
import { Status } from 'embercom/models/data/content-service/ai/ingestion-job-statuses';
import type ContentImportService from 'embercom/services/content-import-service';
import { states } from 'embercom/models/data/matching-system/matching-constants';
import type FinOptInService from 'embercom/services/fin-opt-in-service';
import type { ChannelType } from 'embercom/lib/workflows/fin-workflow-preview';
import { markViewAsSeen } from 'embercom/lib/admin-view-tracking';
import moment from 'moment-timezone';
import { type FinAIAgentSetupRouteModel } from 'embercom/routes/apps/app/automation/fin-ai-agent/setup';
import { type Tone } from 'embercom/components/operator/fin/setup/personality';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';
import { type Action as HeroBannerAction } from 'embercom/components/common/hero-banner';

export interface ConversationContentSettings {
  enabled: boolean;
  numSnippets: number;
  numSnippetsAvailable: number;
  immediatelyAvailableToFin: boolean;
  adminIDs: number[];
  teamIDs: number[];
  contentFolderId: number | null;
}

export interface ConversationContentSettingsWireFormat {
  enabled: boolean;
  num_snippets: number;
  num_snippets_available: number;
  immediately_available_to_fin: boolean;
  admin_ids?: number[];
  team_ids?: number[];
  content_folder_id: number | null;
}

export type FinChannel =
  | 'web'
  | 'ios'
  | 'android'
  | 'facebook'
  | 'whatsapp'
  | 'instagram'
  | 'sms'
  | 'email';

export const FIN_CHAT_CHANNELS = [
  'web',
  'ios',
  'android',
  'facebook',
  'whatsapp',
  'instagram',
  'sms',
];
export const EMAIL_CHANNEL = 'email';

const LAST_VIEWED_QUESTION_EXTRACTION_RUN_ID = 'last-viewed-question-extraction-run-id';
const GG_MILESTONE_ONE_LAUNCH_DATE = '2024-07-25';

export default class SetupController extends Controller {
  @service declare appService: any;
  @service declare intercomEventService: any;
  @service declare store: Store;
  @service declare router: any;
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  @service declare permissionsService: any;
  @service declare contentImportService: ContentImportService;
  @service declare onboardingHomeService: any;
  @service declare finOptInService: FinOptInService;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare aiAgentSetupService: AiAgentSetupService;
  @tracked declare liveProfilesWithAiAnswersEnabled: boolean;
  @tracked declare liveProfilesWithCustomAnswersEnabled: boolean;

  @tracked declare liveFinProfilesCount: number;
  @tracked hardLimitBreached = false;
  @tracked declare conversationContentSettings: ConversationContentSettings;
  @tracked declare hasFinChatWorkflows: boolean;
  @tracked declare hasFinEmailWorkflows: boolean;

  @tracked declare liveFinWorkflowsCount: number;
  @tracked declare liveFinEmailWorkflowsCount: number;
  @tracked declare liveFinChatWorkflowsCount: number;
  @tracked declare liveWorkflowsOverridableByFinSetup: ContentWrapper[];

  @tracked declare finSetupRuleset: $TSFixMe;
  @tracked declare emailSetupRuleset: $TSFixMe;
  @tracked declare templates: Template[];
  @tracked declare activeTab: string;
  @tracked declare previewConfiguration: $TSFixMe;

  @tracked declare latestQuestionExtractionRunId: number;
  @tracked declare shouldShowUpdatedLabel: boolean;
  @tracked declare hasSeenFinLandingPageWithPreview: boolean;
  @tracked declare personalityCustomizationSettings: $TSFixMe;

  queryParams = ['openSectionId', 'previewFin', 'activeTab'];
  @tracked declare openSectionId: string;
  @tracked declare previewFin: string;
  @tracked showCongratulationBanner = false;
  @tracked declare savedTovSetting: Tone;

  declare model: FinAIAgentSetupRouteModel;

  constructor() {
    super(...arguments);
    this.activeTab = this.activeTab || 'add-content';
  }

  get app() {
    return this.appService.app;
  }

  get isAppCreatedAfterGGM1() {
    return moment(this.appService.app.created_at).isAfter(GG_MILESTONE_ONE_LAUNCH_DATE);
  }

  get showFinPreview(): boolean {
    return this.previewFin === 'true';
  }

  get showFinLandingPage(): boolean {
    if (!this.app.hasConsentedToFinIngestion) {
      return true;
    }
    return this.showFinPreview && !this.hasSeenFinLandingPageWithPreview;
  }

  get hasContentReadyForFin(): boolean {
    return this.aiAgentSetupService.hasContentReadyForFin;
  }

  get getPersonalityToneOfVoiceLabelTranslation(): string {
    return this.intl.t(`ai-agent.settings.tone-of-voice.tones.${this.savedTovSetting}.name`);
  }

  get onboardingStep() {
    return this.store.peekRecord('onboarding/home/step', 'display_only_try_fin_for_yourself');
  }

  get isOnboardingStepIncomplete() {
    if (!this.onboardingStep) {
      return false;
    }

    return !this.onboardingStep?.completed;
  }

  get personalityTabHasUnsavedChanges(): boolean {
    return (
      this.personalityCustomizationSettings.hasDirtyAttributes ||
      this.store.peekRecord('operator-identity', 1).hasDirtyAttributes
    );
  }

  get newHeroBannerActions(): HeroBannerAction[] {
    return [
      {
        type: 'link',
        label: 'ai-agent.settings.new-banner.actions.article-set-fin-live-over-chat',
        icon: 'article',
        analyticsId: 'set-fin-live-over-chat-article',
        onClick: () => {
          window.Intercom('showArticle', 8286630); // https://www.intercom.com/help/en/articles/8286630-set-fin-ai-agent-live-over-chat
        },
      },
      {
        type: 'link',
        label: 'ai-agent.settings.new-banner.actions.article-set-fin-live-over-email',
        icon: 'article',
        analyticsId: 'set-fin-live-over-email-article',
        onClick: () => {
          window.Intercom('showArticle', 9356221); // https://www.intercom.com/help/en/articles/9356221-set-fin-ai-agent-live-over-email
        },
      },
      {
        type: 'link',
        label: 'ai-agent.settings.new-banner.actions.article-fin-price-per-resolution',
        icon: 'article',
        analyticsId: 'fin-price-per-resolution-article',
        onClick: () => {
          window.Intercom('showArticle', 8205718); // https://www.intercom.com/help/en/articles/8205718-fin-ai-agent-resolutions
        },
      },
      {
        type: 'link',
        label: 'ai-agent.settings.new-banner.actions.article-guide-leveraging-ai-automation',
        icon: 'book',
        analyticsId: 'guide-leveraging-ai-automation-article',
        onClick: () => {
          window.Intercom('showArticle', 9638929); // https://www.intercom.com/help/en/articles/9638929-get-the-best-from-ai-and-automation
        },
      },
    ];
  }

  @action
  async switchTab(tab: string) {
    if (this.activeTab === 'personality' && this.personalityTabHasUnsavedChanges) {
      let confirmNotSaved = {
        title: this.intl.t('operator.fin.setup.personality.unsaved-changes-modal.title'),
        confirmButtonText: this.intl.t(
          'operator.fin.setup.personality.unsaved-changes-modal.confirm',
        ),
        cancelButtonText: this.intl.t(
          'operator.fin.setup.personality.unsaved-changes-modal.cancel',
        ),
        body: this.intl.t('operator.fin.setup.personality.unsaved-changes-modal.body'),
      };
      let confirmed = await this.intercomConfirmService.confirm(confirmNotSaved);
      if (!confirmed) {
        return;
      }
    }

    this.activeTab = tab;
    if (tab === 'optimize') {
      if (this.latestQuestionExtractionRunId) {
        localStorage.setItem(
          LAST_VIEWED_QUESTION_EXTRACTION_RUN_ID,
          this.latestQuestionExtractionRunId.toString(),
        );
      }
      this.shouldShowUpdatedLabel = false;
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `${tab}-tab`,
    });
  }

  @action
  setOpenSectionId(openSectionId: string) {
    this.openSectionId = openSectionId;
  }

  @action
  async consentToFinIngestion() {
    if (this.showFinPreview && this.isAppCreatedAfterGGM1) {
      markViewAsSeen(this.store, FIN_LANDING_PAGE_PAGEVIEW_KEY);
      this.router.transitionTo('apps.app.automation.fin-ai-agent.setup', {
        queryParams: { previewFin: false },
      });
      return;
    }

    if (
      !this.isOnboardingStepIncomplete &&
      this.showFinPreview &&
      this.app.hasConsentedToFinIngestion
    ) {
      this.router.transitionTo('apps.app.automation.fin-ai-agent.setup', {
        queryParams: { previewFin: false },
      });
      return;
    }

    if (this.isOnboardingStepIncomplete) {
      this.onboardingStep.set('state', 'completed');
      await this.onboardingStep.saveAndRefreshGuide();
    }

    this.previewFin = 'false';

    if (!this.permissionsService.currentAdminCan('can_access_billing_settings')) {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        'can_access_billing_settings',
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'blocked',
        object: 'ingestion_consent_page',
      });
      return;
    }
    await this.consentToFinIngestionHelper();
  }

  private async consentToFinIngestionHelper() {
    let params = {
      app_id: this.app.id,
    };
    try {
      await post('/ember/fin_ingestion_consent', params);
      this.app.set('hasConsentedToFinIngestion', true);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'authorized',
        object: 'ingestion_consent_page',
      });
      this.router.transitionTo('apps.app.automation.fin-ai-agent.setup', {
        queryParams: { previewFin: false },
      });
    } catch (error) {
      this.consentToFinIngestionErrorHandler(error);
    }
  }

  private consentToFinIngestionErrorHandler(error: any) {
    if (error?.jqXHR?.status === 409) {
      // 409 is returned if someone has already consented to Fin ingestion for the workspace
      // This can happen if multiple people load the landing page before consent is given
      // We can safely ignore this error and dismiss the landing page, because we know there is consent from the workspace
      this.app.set('hasConsentedToFinIngestion', true);
      this.router.transitionTo('apps.app.automation.fin-ai-agent.setup', {
        queryParams: { previewFin: false },
      });
      return;
    }
    this.notificationsService.notifyError(this.intl.t('modal.fin-opt-in.request_error'));
    captureException(new Error('Fin ingestion consent request failed'), {
      fingerprint: ['fin-setup', 'consentToFinIngestion'],
      extra: {
        appId: this.app.id,
      },
      tags: {
        responsibleTeam: 'team-ai-agent',
        responsible_team: 'team-ai-agent',
      },
    });
  }

  @action
  async reloadModel() {
    this.send('refreshModel');
  }

  @action
  reloadSavedCustomizationSettings() {
    this.savedTovSetting = this.personalityCustomizationSettings.aiToneOfVoice;
  }

  get showBanner(): boolean {
    return this.contentImportService.finIngestionJobStatusBanner?.display ?? false;
  }

  get workspaceLevelIngestionInProgress(): boolean {
    return (
      this.showBanner &&
      (this.contentImportService.finIngestionJobStatusBanner?.status === Status.InProgress ||
        this.contentImportService.finIngestionJobStatusBanner?.status === Status.Pending)
    );
  }

  get workspaceLevelIngestionCompleted(): boolean {
    return (
      this.showBanner &&
      this.contentImportService.finIngestionJobStatusBanner?.status === Status.Completed
    );
  }

  get canUseEssentialsSetupExperience(): boolean {
    return !this.app.canUseFeature('workflows_core_billing_feature');
  }

  get usageLimitArticleLink() {
    if (this.appService.app.usesResolutionsWithCustomAnswers) {
      return 'https://www.intercom.com/help/en/articles/8443037-how-to-manage-your-fin-usage';
    } else {
      return 'https://www.intercom.com/help/en/articles/8060969-get-notified-of-your-fin-usage';
    }
  }

  get finSetupRulesetStateIsLive(): boolean {
    return this.aiAgentSetupService.finSetupRulesetStateIsLive;
  }

  get totalFinLiveChatCount() {
    return this.liveFinChatWorkflowsCount + this.liveFinProfilesCount;
  }

  get finChatIsLive(): boolean {
    return (
      this.aiAgentSetupService.finStats.workflowsCount.liveFinChatWorkflowsCount > 0 ||
      this.aiAgentSetupService.finStats.liveFinProfilesCount > 0 ||
      this.aiAgentSetupService.isFinSetupChatRulesetLive
    );
  }

  get finEmailIsLive(): boolean {
    return (
      this.aiAgentSetupService.finStats.workflowsCount.liveFinEmailWorkflowsCount > 0 ||
      this.aiAgentSetupService.isFinSetupEmailRulesetLive
    );
  }

  get finIsLive(): boolean {
    return (
      this.aiAgentSetupService.isFinSetupChatRulesetLive ||
      this.aiAgentSetupService.isFinSetupEmailRulesetLive ||
      this.liveFinWorkflowsCount > 0 ||
      this.liveProfilesWithAiAnswersEnabled
    );
  }

  get lastUpdatedTime() {
    let timestampInMilliseconds = this.latestQuestionExtractionRunId * 1000;
    return new Date(timestampInMilliseconds);
  }

  get shouldShowDataAndActionsTabForFinOnboarding() {
    return this.app.canShowDataAndActionsTabForFinOnboarding;
  }

  get finWorkflowsUrl(): string {
    return this.router.urlFor('apps.app.automation.workflows-overview', {
      queryParams: {
        hasFin: true,
        state: states.live,
      },
    });
  }

  private workflowHasRelevantChannels(targetChannels: string[], workflow: ContentWrapper): boolean {
    return workflow.contents.firstObject?.contentData.target_channels?.some((channel: FinChannel) =>
      targetChannels.includes(channel),
    );
  }

  get liveWorkflowsOverridableByFinChatSetup(): ContentWrapper[] {
    return this.liveWorkflowsOverridableByFinSetup.filter((workflow: ContentWrapper) => {
      return this.workflowHasRelevantChannels(FIN_CHAT_CHANNELS, workflow);
    });
  }

  get liveWorkflowsOverridableByFinEmailSetup(): ContentWrapper[] {
    return this.liveWorkflowsOverridableByFinSetup.filter((workflow: ContentWrapper) => {
      return this.workflowHasRelevantChannels([EMAIL_CHANNEL], workflow);
    });
  }

  @action
  async reloadSetupRuleset(setupType: ChannelType) {
    return this.aiAgentSetupService.loadSetupRuleset(setupType);
  }

  @action
  trackWorkflowsClickEvent(channel: string, event: Event) {
    if ((event.target as HTMLElement).nodeName === 'A') {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: `fin_setup_${channel}_header_tab_workflows_link`,
      });
    }
  }
}
