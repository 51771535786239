/* RESPONSIBLE TEAM: team-growth-opportunities */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  PRICING_5_X_EARLY_STAGE_SOLUTION_IDS,
  OFFERED_SELF_SERVE_ANNUALLY_BILLED_SOLUTIONS,
} from 'embercom/lib/billing';

export default class TeamsController extends Controller {
  @service media;
  @service customerService;
  @service purchaseAnalyticsService;
  @service earlyStageService;
  @service intl;

  @tracked shouldShowCart = false;
  @tracked selectedLocale;

  queryParams = [
    'developer',
    'gclid',
    {
      initiatedByShopify: 'initiated_by_shopify',
      addOnPlanIds: 'add_on_plan_ids',
      featureFlagsFromUrl: 'feature_flags',
      productId: 'product_id',
      solutionId: 'solution_id',
      requireVerification: 'require_verification',
      selectedLocale: 'locale',
      product: 'product',
    },
  ];

  addOnPlanIds = [];
  developer = false;
  featureFlagsFromUrl = null;
  gclid = null;
  productId = null;
  requireVerification = false;
  solutionId = null;
  product = '';

  get parsedFeatureFlags() {
    let urlFlags = this.featureFlagsFromUrl;
    if (urlFlags) {
      try {
        return JSON.parse(urlFlags);
      } catch (e) {
        // Value is likely a string instead of JSON string
        if (e instanceof SyntaxError) {
          return [urlFlags];
        } else {
          console.error(e);
        }
      }
    }

    return null;
  }

  get price() {
    return this.model.prices?.firstObject;
  }

  get isEarlyStage() {
    return this.earlyStageService.isEarlyStage(this.solutionId);
  }

  get billingPeriodDurationInMonths() {
    if (OFFERED_SELF_SERVE_ANNUALLY_BILLED_SOLUTIONS.includes(this.solutionId)) {
      return 12;
    }
    return 1;
  }

  get shouldShowPricing5Checkout() {
    return PRICING_5_X_EARLY_STAGE_SOLUTION_IDS.includes(this.solutionId);
  }

  @action async changeLocale(newLocale) {
    this.selectedLocale = newLocale;
    await this.intl.switchLocale(newLocale);
  }
}
