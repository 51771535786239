/* RESPONSIBLE TEAM: team-messenger */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import RouteRegexes from 'embercom/lib/route-regexes';
import type Router from '@ember/routing/router-service';

export default class Messenger extends Controller {
  @service declare router: Router;
  @service declare appService: $TSFixMe;

  get app() {
    return this.appService.app;
  }

  queryParams = [];

  declare model: {
    settings: any;
    identity: any;
  };

  get selectedTab(): string | undefined {
    if (RouteRegexes.newSettings.messenger.web.test(this.router.currentRouteName)) {
      return 'web';
    } else if (RouteRegexes.newSettings.messenger.mobileSdk.test(this.router.currentRouteName)) {
      return 'mobile-sdk';
    } else if (
      RouteRegexes.newSettings.messenger.conversations.test(this.router.currentRouteName)
    ) {
      return 'conversations';
    } else if (RouteRegexes.newSettings.messenger.install.test(this.router.currentRouteName)) {
      return 'install';
    } else if (RouteRegexes.newSettings.messenger.general.test(this.router.currentRouteName)) {
      return 'general';
    } else {
      return undefined;
    }
  }

  @action
  setSelectedTab(tab: string) {
    this.navigateToTabs(tab);
  }

  private navigateToTabs(tab: string) {
    switch (tab) {
      case 'web':
        this.router.replaceWith('apps.app.settings.channels.messenger.web', {
          queryParams: { tab: 'content', section: 'layout-and-spaces' },
        });
        break;
      case 'mobile-sdk':
        this.router.replaceWith('apps.app.settings.channels.messenger.mobile-sdk', {
          queryParams: { tab: 'content', section: 'layout-and-spaces' },
        });
        break;
      case 'conversations':
        this.router.replaceWith('apps.app.settings.channels.messenger.conversations', {
          queryParams: { tab: 'teammates', section: 'start-conversation-button' },
        });
        break;
      case 'install':
        this.router.replaceWith('apps.app.settings.channels.messenger.install', {
          queryParams: { tab: 'web', section: 'messenger-setup' },
        });
        break;
      case 'general':
        this.router.replaceWith('apps.app.settings.channels.messenger.general', {
          queryParams: { section: 'inbound-volume' },
        });
        break;
      default:
        this.router.replaceWith('apps.app.settings.channels.messenger.web', {
          queryParams: { tab: 'content', section: 'layout-and-spaces' },
        });
        break;
    }
  }
}
