/* RESPONSIBLE TEAM: team-channels */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { underscore } from '@ember/string';
import { tracked } from '@glimmer/tracking';
import { getDomainName } from 'embercom/lib/email';
import type DomainService from 'embercom/services/domain-service';
import type { Domain } from 'embercom/services/domain-service';

export default class EmailController extends Controller {
  queryParams = ['tab', 'highlight', 'step', 'email', 'domainName'];

  @service declare domainService: DomainService;
  @service declare intercomEventService: any;

  @tracked declare tab: string;
  @tracked declare step: string | null;
  @tracked highlight: string | null = null;
  @tracked declare email: string | null;
  @tracked declare domainName: string | null;
  @tracked declare previousStepForDomainSetup: string | null;

  get selectedTab() {
    return this.tab;
  }

  get domain(): Domain | null {
    return this.domains.find((domain) => domain.name === this.domainName) || null;
  }

  get domains() {
    return this.domainService.domains;
  }

  @action
  changeTab(tab: string) {
    // since highlighting only applies to the settings tab,
    // it's safe to forget highlight when leaving that tab
    if (this.tab === 'email-settings' && tab !== 'email-settings') {
      this.highlight = null;
    }
    this.tab = tab;
  }

  @action
  setPreviousStepForDomainSetup(step: string | null) {
    this.previousStepForDomainSetup = step;
  }

  @action
  changeStep(step: string | null, place: string) {
    this.step = step;
    if (step === null) {
      this.setEmail(null);
      this.setDomainName(null);
    }
    this.trackEvent(place);
  }

  trackEvent(place: string) {
    let object = this.step ? `navigate_to_${underscore(this.step)}` : 'close_sidebar';
    let context = `${this.tab}-tab`;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
      place,
      context,
    });
  }

  @action
  setDomainName(domainName: string | null) {
    this.domainName = domainName;
  }

  @action
  async setEmail(email: string | null) {
    this.email = email;
    if (email) {
      let domainFound = this.domains.find((domain) => domain.name === getDomainName(email));
      if (domainFound) {
        this.setDomainName(domainFound.name);
      }
      await this.domainService.load();
    }
  }
}
