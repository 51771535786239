/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { type Router } from '@ember/routing';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { captureException } from '@sentry/browser';
import type IntlService from 'ember-intl/services/intl';

export default class RemoveTeammateController extends Controller {
  queryParams = [''];

  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare router: Router;

  @tracked selectionIsValid = false;

  declare model: $TSFixMe;

  @action
  async removeTeammate() {
    let { teammate, reassignments } = this.model;
    let permission = teammate.currentAppPermissions;

    try {
      await permission.destroyRecord({ adapterOptions: { reassignments } });
      this.appService.app.admins.removeObject(teammate);
      permission.unloadRecord();
      teammate.unloadRecord();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.teammates.remove-teammate.notification.successful-removal', {
          teammateName: teammate.displayAs,
          appName: this.model.app.name,
        }),
      );
      this.router.transitionTo('apps.app.settings.workspace.teammates');
    } catch (error) {
      if (error.jqXHR.status === 403) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.message);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.teammates.remove-teammate.notification.unexpected-failure'),
        );
        captureException(error);
      }
    }
  }
}
