/* RESPONSIBLE TEAM: team-workflows */
import Controller, { inject as controller } from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency-decorators';
import { modifier } from 'ember-modifier';

const CREATE_SIMPLE_AUTOMATION_BANNER_KEY = 'simple-automations-create-banner';

export default class AppsAppAutomationBasicsController extends Controller {
  @service appService;
  @service intercomEventService;
  @service bannerDismissalService;

  queryParams = ['for', 'section'];
  @tracked for = 'users';
  @tracked section;
  @controller('apps.app.automation') automationController;

  @tracked showSimpleAutomationsBanner = false;

  onLoad = modifier(
    () => {
      this.checkSimpleAutomationsBannerDismissal.perform();
    },
    { eager: false },
  );

  get app() {
    return this.appService.app;
  }

  @action
  switchTab(forRole) {
    this.section = null;
    this.for = forRole;
  }

  @action
  openSection(sectionId) {
    this.section = sectionId;
  }

  @dropTask
  *checkSimpleAutomationsBannerDismissal() {
    let hasDismissed = yield this.bannerDismissalService.hasDismissed(
      CREATE_SIMPLE_AUTOMATION_BANNER_KEY,
    );
    this.showSimpleAutomationsBanner = !hasDismissed;
  }

  @action
  recordAnalyticsEvent(action, object, section) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      section,
      context: 'automation.basics',
      place: 'platform',
    });
  }

  @action
  dismissSimpleAutomationsBanner() {
    this.showSimpleAutomationsBanner = false;
    this.bannerDismissalService.dismiss(CREATE_SIMPLE_AUTOMATION_BANNER_KEY);
    this.recordAnalyticsEvent('clicked', 'automation-dismiss-button', 'simple-automations-banner');
  }
}
