/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/require-computed-macros */

import Controller from '@ember/controller';
import { action, computed, set } from '@ember/object';
import { alias, equal, not, oneWay, or, readOnly, reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import UserAgentDetector from '@intercom/pulse/lib/user-agent-detector';
import { task, timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import {
  OFFERED_SELF_SERVE_ANNUALLY_BILLED_SOLUTIONS,
  PLAN_DATA,
  PRICING_5_SOLUTION_IDS,
  PRICING_5_X_EARLY_STAGE_SOLUTION_IDS,
  VALUE_BASED_PRICING_FEB2022_STARTER_SOLUTION_ID,
  percentDiscountForSolution,
  plansFromSolution,
} from 'embercom/lib/billing';
import { hideIntercomWidgetWhenLoaded } from 'embercom/lib/intercom-widget-helper';
import { PRICING_5_X_GROUPS, PRICING_5_X_SMS_GROUP_KEY } from 'embercom/lib/purchase/constants';

const DEBOUNCE_MS = ENV.APP._250MS;

export default Controller.extend({
  queryParams: {
    addOn: 'add_on',
    addOnPlanIds: 'add_on_plan_ids',
    gclid: 'gclid',
    planId: 'plan_id',
    emailSubmissionId: 'email_submission_id',
    seats: 'seats',
    convertCardlessTrialSubscription: 'cardless_trial',
    extendedTrial: 'extended_trial',
    billingPeriod: 'billing_period',
    seatNumber: 'seat_number',
  },

  isSafari: UserAgentDetector.isSafari(),
  needToFetchPrice: not('matchingPlansAndUsagePrice'),
  notSafari: not('isSafari'),
  price: or('matchingPlansAndUsagePrice', 'matchingPlansPrice'),
  solutionPlusAddOnPrice: reads('price'),
  addOn: null,
  addOnPlanIds: [],
  planId: null,
  extendedTrial: null,
  billingPeriod: undefined,
  seatNumber: undefined,
  gclid: null,
  solutionId: '',
  emailSubmissionId: undefined,
  navStep: null,
  seats: 0,
  convertCardlessTrialSubscription: '',
  checkout: null,

  appService: service(),
  customerService: service(),
  purchaseAnalyticsService: service(),
  media: service(),
  router: service(),
  earlyStageService: service(),
  signupService: service(),
  session: service(),
  intl: service(),
  cardlessTrialService: service(),

  app: readOnly('appService.app'),
  billingPeriodDurationInMonthsInternal: alias('signupService.billingPeriod'),
  isBillingPeriodSelected: reads('signupService.isBillingPeriodSelected'),
  selectedLocale: reads('intl.primaryLocale'),
  isInSelfServeTrial: reads('cardlessTrialService.isInSelfServeTrial'),
  customerHasEverHadSubscriptionProperty: undefined,

  mobileDevice: oneWay('media.isMobile'),
  prices: null,
  couponCode: null,
  couponDiscountAmountInCents: 0,
  shouldShowCart: false,

  usageMetrics: [
    'messages_sent',
    'fin_ai_bot_resolution',
    'email_campaigns',
    'sms_sent_received',
    'whatsApp',
    'phone',
  ],

  showExpiredTrialCheckout: computed(
    'app.teamPurchaseExperienceTrialExtensionsVariant',
    'extendedTrial',
    function () {
      return this.app.teamPurchaseExperienceTrialExtensionsVariant && this.extendedTrial === 'true';
    },
  ),

  notEarlyStageApplicationOrVerifyRoute: computed(
    'isVerifyPageRoute',
    'isEarlyStageApplicationRoute',
    function () {
      // Allow early stage and verify route past new checkout
      return !this.isVerifyPageRoute && !this.isEarlyStageApplicationRoute;
    },
  ),

  isEarlyStage: computed('solutionId', function () {
    return this.earlyStageService.isEarlyStage(this.solutionId);
  }),

  isEarlyStageProgression: computed(
    'customerService.{earlyStageGraduation.pricing5_X_Graduation,customer.cancellationRequestedOutsideMigration}',
    'app.canUseNewProgressionExperience',
    function () {
      return (
        this.customerService.earlyStageGraduation?.pricing5_X_Graduation &&
        this.app.canUseNewProgressionExperience &&
        !this.customerService.customer.cancellationRequestedOutsideMigration
      );
    },
  ),

  isVerifyPageRoute: equal('router.currentRouteName', 'apps.app.teams-checkout.verify'),
  isUsageRoute: equal('router.currentRouteName', 'apps.app.teams-checkout.usage'),
  isEarlyStageApplicationRoute: equal(
    'router.currentRouteName',
    'apps.app.teams-checkout.early-stage-application',
  ),
  shouldShowSidebar: computed('isEarlyStageApplicationRoute', 'isVerifyPageRoute', function () {
    return !this.isEarlyStageApplicationRoute && !this.isVerifyPageRoute;
  }),

  solutionDiscount: computed('solutionId', 'app', function () {
    return percentDiscountForSolution(this.solutionId);
  }),

  shouldShowBasketCTAs: computed('router.currentRouteName', 'solutionId', function () {
    return !(
      this.router.currentRouteName === 'apps.app.teams-checkout.confirm' ||
      PRICING_5_X_EARLY_STAGE_SOLUTION_IDS.includes(this.solutionId)
    );
  }),

  solutionPlanIds: computed('solutionId', 'app', function () {
    return plansFromSolution(this.solutionId, this.app).map((planId) => parseInt(planId, 10));
  }),

  add_ons: computed('addOnPlanIds', function () {
    return this.addOnPlanIds.map((id) => PLAN_DATA[id]);
  }),

  solutionPrice: computed('prices', 'solutionPlanIds', 'seats', function () {
    let price = this.prices.find((price) =>
      price.hasSamePlansAndSeats(this.solutionPlanIds, this.seats),
    );
    return price;
  }),

  planIds: computed('solutionId', 'addOnPlanIds', 'app', function () {
    return plansFromSolution(this.solutionId)
      .concat(this.addOnPlanIds)
      .map((planId) => parseInt(planId, 10));
  }),

  matchingPlansAndUsagePrice: computed('prices', 'planIds.[]', 'seats', function () {
    return this.prices.find((price) => price.hasSamePlansAndSeats(this.planIds, this.seats));
  }),

  matchingPlansPrice: computed('prices', 'planIds.[]', function () {
    return this.prices.find((price) => price.hasSamePlans(this.planIds));
  }),

  customerHasEverHadSubscription: computed('customerHasEverHadSubscriptionProperty', function () {
    this.fetchCustomerHasEverHadSubscription();
    return this.customerHasEverHadSubscriptionProperty;
  }),

  hideWidgetIfAppropriate() {
    if (this.mobileDevice) {
      hideIntercomWidgetWhenLoaded();
    }
  },

  shouldOfferAnnualBillingPeriods: computed('solutionId', function () {
    return OFFERED_SELF_SERVE_ANNUALLY_BILLED_SOLUTIONS.includes(this.solutionId);
  }),

  billingPeriodDurationInMonths: computed(
    'shouldOfferAnnualBillingPeriods',
    'billingPeriodDurationInMonthsInternal',
    function () {
      if (this.shouldOfferAnnualBillingPeriods) {
        return this.billingPeriodDurationInMonthsInternal;
      }
      return 1;
    },
  ),

  isPricing5EarlyStageSolution: computed('solutionId', function () {
    return PRICING_5_X_EARLY_STAGE_SOLUTION_IDS.includes(this.solutionId);
  }),

  showBillingPeriodDetails: computed(
    'shouldOfferAnnualBillingPeriods',
    'router.currentRouteName',
    function () {
      return (
        this.shouldOfferAnnualBillingPeriods &&
        this.router?.currentRouteName !== 'apps.app.teams-checkout.verify'
      );
    },
  ),

  usageParameterOverrides: computed('solutionId', function () {
    // https://github.com/intercom/intercom/issues/307259
    // usage parameters can be found here: https://github.com/intercom/billing/blob/f97950d78f98071b5d4d31bb781101cfbd1159d7/app/models/usage_parameter_set.rb#L186.
    // during the checkout flow, all the usages used during the trial period should not be counted.
    // so we override them here to ensure the customer is seeing the correct amount.
    if (this.solutionId === VALUE_BASED_PRICING_FEB2022_STARTER_SOLUTION_ID) {
      return {
        resolutions: 0,
      };
    } else if (PRICING_5_SOLUTION_IDS.includes(this.solutionId)) {
      return {
        resolutions: 0,
        whatsapp_inbound: 0,
        whatsapp_outbound: 0,
        resolutions_with_custom_answers: 0,
        emails_sent: 0,
        messages_sent: 0,
        ...PRICING_5_X_GROUPS[PRICING_5_X_SMS_GROUP_KEY].reduce((smsMetrics, key) => {
          smsMetrics[key] = 0;
          return smsMetrics;
        }, {}),
      };
    }
  }),

  shouldShowPricing5Checkout: computed('solutionId', function () {
    return PRICING_5_SOLUTION_IDS.includes(this.solutionId);
  }),

  toggleAddon: action(function (id, source) {
    let active = this.addOnPlanIds.includes(id);
    active ? this.addOnPlanIds.removeObject(id) : this.addOnPlanIds.addObject(id);

    this.purchaseAnalyticsService.trackEvent({
      action: active ? 'removed' : 'selected',
      section: 'add_ons_cta',
      planIds: [this.planId],
      object: 'add_on',
      context: source,
      place: 'add_ons_page',
      mobile: this.mobileDevice,
    });
  }),

  decrementSeats: action(function () {
    this.set('seats', this.seats - 1);
    this.fetchNewPrices.perform();

    this.trackSeatPickerSelections('decrement-seats');
  }),

  incrementSeats: action(function () {
    this.set('seats', this.seats + 1);
    this.fetchNewPrices.perform();

    this.trackSeatPickerSelections('increment-seats');
  }),

  trackSeatPickerSelections(action) {
    this.purchaseAnalyticsService.trackEvent({
      action,
      section: 'sign_up_flow',
      object: 'usage_step',
      place: 'usage',
      seats: this.seats,
      planIds: this.planIds,
      mobile: this.mobileDevice,
    });
  },

  minimumSeats: computed('solutionId', function () {
    return this.appService.minimumSeats(this.solutionId);
  }),

  setSeatValueToMinimumSeatsValue() {
    if (this.seats === 0 && !this.isEarlyStage) {
      this.set('seats', this.minimumSeats);
    }
  },

  fetchNewPrices: task(function* () {
    if (this.needToFetchPrice) {
      yield timeout(DEBOUNCE_MS);
      let newPrices = yield this.customerService.fetchSolutionPricesWithSeats(
        this.solutionId,
        this.planIds,
        this.couponCode,
        'teams-checkout-controller',
        true,
        this.seats,
        this.usageParameterOverrides,
      );
      this.addNewPricesToLoadedPrices(newPrices);
    }
  }).restartable(),

  addNewPricesToLoadedPrices(newPrices) {
    this.set('prices', this.prices.concat(newPrices.toArray()));
  },

  setBillingPeriodDurationInMonths: action(function (billingPeriodDurationInMonths) {
    this.set('signupService.billingPeriod', billingPeriodDurationInMonths);
    this.set('isBillingPeriodSelected', true);

    this.purchaseAnalyticsService.trackEvent({
      action: 'selected',
      section: 'sign_up_flow',
      place: 'add_ons_page',
      object: 'plan_selector',
      billing_period: billingPeriodDurationInMonths,
      planIds: [this.planId],
    });
  }),

  toggleCart: action(function () {
    this.set('shouldShowCart', !this.shouldShowCart);

    this.purchaseAnalyticsService.trackEvent({
      action: this.shouldShowCart ? 'collapsed' : 'expanded',
      object: 'cart',
      context: 'checkout_page',
      place: 'checkout_page',
      mobile: true,
    });
  }),

  changeLocale: action(function (newLocale) {
    this.session.changeLanguage(newLocale, this.app, this.app.currentAdmin);
  }),

  redirectToOnboardingHome() {
    this.router.transitionTo(this.app.onboardingHomeRoute);
  },

  async fetchCustomerHasEverHadSubscription() {
    let customer;
    //to prevent too many calls to customer service calling the store
    //we will track customerHasEverHadSubscription
    if (this.customerHasEverHadSubscriptionProperty === undefined) {
      customer = await this.customerService.refreshCustomer();
      set(this, 'customerHasEverHadSubscriptionProperty', customer.hasEverHadSubscription);
    }
  },
});
