/* RESPONSIBLE TEAM: team-conversational-knowledge */
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import { type EntityType } from 'embercom/models/data/entity-types';
import {
  AI_CONTENT_ENTITIES_NAMES,
  CONTENT_REVIEWS_DEFAULT_COLUMNS,
  CONTENT_REVIEW_SELECTABLE_COLUMNS,
} from 'embercom/lib/ai-content-library/constants';
import {
  objectNames,
  objectNamesToKeys,
} from 'embercom/models/data/matching-system/matching-constants';
import { type TableColumn } from 'embercom/components/content-service/ai-content-library';
import { DRAWER_EDITOR_QUERY_PARAMS } from 'embercom/services/knowledge-hub-drawer-editor-service';
import { type EditorMode } from 'embercom/components/knowledge-hub/content-editor/drawer-editor';

export default class FinContentReviewsController extends Controller {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;

  selectedContentQueryParams = Object.values(AI_CONTENT_ENTITIES_NAMES);
  queryParams = [
    'searchTerm',
    { selectedContentType: 'content' },
    { selectedContentId: 'id' },
    'actionType',
    ...DRAWER_EDITOR_QUERY_PARAMS,
  ];

  @tracked declare searchTerm?: string;
  @tracked declare selectedContentType?: string;
  @tracked declare selectedContentId?: number;
  @tracked declare predicates?: Array<any>;
  @tracked declare createdBy?: string;
  @tracked declare sortBy: string | null;
  @tracked declare sortDirection: string | null;
  @tracked declare actionType?: string;

  // Drawer Editor query params
  @tracked activeContentId?: number;
  @tracked activeContentType?: string;
  @tracked editorMode?: EditorMode;

  get columns(): TableColumn[] {
    let columns = CONTENT_REVIEWS_DEFAULT_COLUMNS as TableColumn[];
    return columns.map((column) => {
      return {
        ...column,
        label: column.labelIntlKey ? this.intl.t(column.labelIntlKey) : '',
      };
    });
  }

  get selectableColumns() {
    let columns = CONTENT_REVIEW_SELECTABLE_COLUMNS;
    if (this.appService.app.canUseSuggestionColumns) {
      columns = columns.filter((column) => column.value !== 'createdBy');
    }
    return columns;
  }

  get supportedFilters(): Array<{ text: string; icon: string; value: string }> {
    let items = [
      {
        text: this.intl.t('ai-content-library.filters.date'),
        icon: 'calendar',
        value: 'date',
      },
    ];

    if (!this.appService.app.canUseSuggestionColumns) {
      items.push({
        text: this.intl.t('ai-content-library.content-reviews.filters.suggested-by'),
        icon: 'person',
        value: 'createdBy',
      });
    }

    return items;
  }

  get selectedContent(): { contentType?: EntityType; contentId?: number } | null {
    if (!this.selectedContentType || !this.selectedContentId) {
      return null;
    }
    return {
      contentType: Number(objectNamesToKeys[this.selectedContentType]),
      contentId: this.selectedContentId,
    };
  }

  @action
  resetFilters(): void {
    this.searchTerm = undefined;
    this.predicates = undefined;
    this.createdBy = undefined;
    this.sortBy = null;
    this.sortDirection = null;
  }

  @action
  setSearchTerm(searchTerm?: string): void {
    this.searchTerm = searchTerm;
  }

  @action
  setSelectedContent(content: { contentType: EntityType; contentId: number }) {
    this.selectedContentType = objectNames[content.contentType];
    this.selectedContentId = content.contentId;
    this.actionType = undefined; // remove action type query param
  }

  @action
  setPredicates(predicates?: Array<any>): void {
    this.predicates = predicates;
  }

  @action
  setSortBy(sortBy: string | null): void {
    this.sortBy = sortBy;
  }

  @action
  setSortDirection(sortDirection: string | null): void {
    this.sortDirection = sortDirection;
  }

  @action
  setCreatedBy(createdBy?: string): void {
    this.createdBy = createdBy;
  }
}
