/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';

export default class VerifyController extends Controller {
  @service store;
  @service media;
  @service appService;
  @service gtmService;
  @service purchaseAnalyticsService;
  @service iamService;
  @service intl;
  @service router;
  @service intercomEventService;
  @service notificationsService;

  @tracked developer = false;
  @tracked emailResent = false;

  get admin() {
    return this.appService.app.currentAdmin;
  }

  get mobileDevice() {
    return this.media.isMobile;
  }

  get mobileCart() {
    return !this.media.isDesktop;
  }

  @action
  openMessenger(event) {
    event.preventDefault();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `ask_for_help_email_verification`,
    });
    showNewMessageInIntercomWidget(
      this.intl.t('teams-checkout.verify.issue_verify_email_address', {
        adminEmail: this.admin.email,
      }),
    );
  }

  @task
  *resendSignupValidationEmail() {
    try {
      yield this.admin.resendSignupValidationEmail();
      this.emailResent = true;
    } catch (error) {
      console.error(error);
      if (error.jqXHR.status === 409) {
        this.notificationsService.notifyConfirmation(
          this.intl.t('teams-checkout.verify.email_already_verified'),
        );
        this.router.transitionTo('apps.app.home');
      } else {
        this.notificationsService.notifyError(
          this.intl.t('teams-checkout.verify.couldnt_resend_verification_email'),
        );
      }
    }
  }
}
