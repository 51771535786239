/* RESPONSIBLE TEAM: team-standalone */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { DRAWER_EDITOR_QUERY_PARAMS } from 'embercom/services/knowledge-hub-drawer-editor-service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { tracked } from '@glimmer/tracking';
import type { EditorMode } from 'embercom/components/knowledge-hub/content-editor/drawer-editor';
import { action } from '@ember/object';
import type { OnContentUpdateCallbackFunctionArgs } from 'embercom/services/knowledge-hub-editor-service';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency-decorators';
import { timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';

export default class FinStandaloneBaseChannelsController extends Controller {
  @service declare knowledgeHubService: KnowledgeHubService;

  queryParams = [...DRAWER_EDITOR_QUERY_PARAMS];

  // Drawer Editor query params
  @tracked activeContentId?: number;
  @tracked activeContentType?: string;
  @tracked editorMode?: EditorMode;

  @action
  handleContentUpdate(update: OnContentUpdateCallbackFunctionArgs) {
    if (['add', 'delete'].includes(update.type)) {
      taskFor(this.reloadKnowledgeUsageSummary).perform();
    }
  }

  @task({ restartable: true })
  *reloadKnowledgeUsageSummary() {
    // Delay to allow the ES index to update
    yield timeout(ENV.APP._500MS);
    yield this.knowledgeHubService.fetchKnowledgeUsageSummary();
  }
}
