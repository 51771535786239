/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import { alias, notEmpty } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import safeWindowOpen from 'embercom/lib/safe-window-open';

export default Controller.extend({
  notificationsService: service(),
  intercomConfirmService: service(),
  webhooks: alias('model.webhooks'),
  hasWebhooks: notEmpty('webhooks'),

  deleteWebhook: task(function* (webhook) {
    let options = {
      title: 'Are you sure you want to delete?',
      body: "This is permanent. You won't be able to get your webhook back.",
      confirmButtonText: 'Delete webhook',
      primaryButtonType: 'primary-destructive',
    };

    if (yield this.intercomConfirmService.confirm(options)) {
      try {
        yield webhook.destroyRecord();
        this.notificationsService.notifyConfirmation('The webhook was deleted');
      } catch (e) {
        this.notificationsService.notifyError('The webhook could not be deleted');
      }
    }
  }).drop(),

  sendPing: task(function* (webhook) {
    try {
      yield webhook.ping();
      this.notificationsService.notifyConfirmation('Ping sent');
    } catch (e) {
      this.notificationsService.notifyError('Ping unsuccessful');
    }
  }).drop(),

  openDeveloperDocumentation: action(function () {
    safeWindowOpen(
      'https://developers.intercom.com/building-apps/docs/setting-up-webhooks',
      '_blank',
    );
  }),
});
